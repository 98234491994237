import React from "react";
import Layout, { PageContainer } from "../components/Layout";
import { DarkGraySection, BodyText } from "../styles";
const NotFoundPage = () => (
  <PageContainer>
    <Layout>
      <DarkGraySection>
        <BodyText>
          <h1>NOT FOUND</h1>
          <p>We couldn't find that.</p>
        </BodyText>
      </DarkGraySection>
    </Layout>
  </PageContainer>
);

export default NotFoundPage;
